import Vue from 'vue';
import { mapGetters } from 'vuex';
import SuluSender from '~/api/sulu-sender';

Vue.mixin({
  computed: {
    ...mapGetters(['isMobile']),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
    isSulu() {
      return process.env.type === 'sulu';
    },
    suluUrl() {
      return process.env.suluURL;
    },
  },
});

Vue.prototype.$sulu = new SuluSender();