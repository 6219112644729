import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95864fd6 = () => interopDefault(import('../src/pages/flights.vue' /* webpackChunkName: "pages/flights" */))
const _2daa9698 = () => interopDefault(import('../src/pages/lounge.vue' /* webpackChunkName: "pages/lounge" */))
const _043696f4 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _bbe7e42c = () => interopDefault(import('../src/pages/tablo.vue' /* webpackChunkName: "pages/tablo" */))
const _2df76a8c = () => interopDefault(import('../src/pages/for-big-planes/contacts.vue' /* webpackChunkName: "pages/for-big-planes/contacts" */))
const _7aa816c9 = () => interopDefault(import('../src/pages/for-passengers/feedback.vue' /* webpackChunkName: "pages/for-passengers/feedback" */))
const _f9d83efa = () => interopDefault(import('../src/pages/for-passengers/naiti-oplatit-zakaz.vue' /* webpackChunkName: "pages/for-passengers/naiti-oplatit-zakaz" */))
const _0cba8700 = () => interopDefault(import('../src/pages/for-passengers/proverit-skachat-bilet.vue' /* webpackChunkName: "pages/for-passengers/proverit-skachat-bilet" */))
const _7b6c9b6f = () => interopDefault(import('../src/pages/for-passengers/schedule-of-flights.vue' /* webpackChunkName: "pages/for-passengers/schedule-of-flights" */))
const _545fd221 = () => interopDefault(import('../src/pages/for-passengers/zayavka-na-vozvratobmen-bileta.vue' /* webpackChunkName: "pages/for-passengers/zayavka-na-vozvratobmen-bileta" */))
const _30382438 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _782295fe = () => interopDefault(import('../src/pages/news/_.vue' /* webpackChunkName: "pages/news/_" */))
const _263d03f1 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/flights",
    component: _95864fd6,
    meta: {"name":"flights","layout":"index"},
    name: "flights"
  }, {
    path: "/lounge",
    component: _2daa9698,
    meta: {"name":"lounge","layout":"index"},
    name: "lounge"
  }, {
    path: "/news",
    component: _043696f4,
    meta: {"name":"news-list-page","layout":"index"},
    name: "news"
  }, {
    path: "/tablo",
    component: _bbe7e42c,
    meta: {"name":"tablo","layout":"index"},
    name: "tablo"
  }, {
    path: "/for-big-planes/contacts",
    component: _2df76a8c,
    meta: {"name":"contacts","layout":"index"},
    name: "for-big-planes-contacts"
  }, {
    path: "/for-passengers/feedback",
    component: _7aa816c9,
    meta: {"name":"feedback","layout":"index"},
    name: "for-passengers-feedback"
  }, {
    path: "/for-passengers/naiti-oplatit-zakaz",
    component: _f9d83efa,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-naiti-oplatit-zakaz"
  }, {
    path: "/for-passengers/proverit-skachat-bilet",
    component: _0cba8700,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-proverit-skachat-bilet"
  }, {
    path: "/for-passengers/schedule-of-flights",
    component: _7b6c9b6f,
    meta: {"name":"schedule","layout":"index"},
    name: "for-passengers-schedule-of-flights"
  }, {
    path: "/for-passengers/zayavka-na-vozvratobmen-bileta",
    component: _545fd221,
    meta: {"name":"bids-request","layout":"index"},
    name: "for-passengers-zayavka-na-vozvratobmen-bileta"
  }, {
    path: "/",
    component: _30382438,
    meta: {"name":"index","layout":"index"},
    name: "index"
  }, {
    path: "/news/*",
    component: _782295fe,
    meta: {"name":"news-page","layout":"index"},
    name: "news-all"
  }, {
    path: "/*",
    component: _263d03f1,
    meta: {"name":"headless-page","layout":"index"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
